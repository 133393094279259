/*
 * Created by Paul Engelke on 26 March 2021.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for managing the category redux state.
 */
const CategoryActionTypes = ActionTypeUtility.createActionTypes('CATEGORIES', {
  REQUEST: 'REQUEST',
  FAIL_REQUEST: 'FAIL_REQUEST',
  SET: 'SET',
  MERGE: 'MERGE',
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  DELETE_SUBCATEGORY: 'DELETE_SUBCATEGORY',
});

export default CategoryActionTypes;
