/*
 * Created by Paul Engelke on 18 March 2021.
 */

import {
  CacheLifetime,
  LocalCache,
  LocalCacheRegistry
} from "@hti-ui/local-cache";
import SessionActionTypes from "../constants/action-types/sessionActionTypes";
import HttpManager from "../utils/httpManager";
import SecurityUtility from "../utils/securityUtility";

const cache = new LocalCache();
LocalCacheRegistry.register(cache);

/**
 * Sets the user's basic information, extracted from the given JWT token.
 *
 * @param {string} token A JWT token containing the user's information.
 * @return {function(*,*): Promise<Object>}
 */
export const setUserViaToken = (token) => (dispatch) => {

  const {name, surname, userID, customerID, admin, customerType} =
      SecurityUtility.parseToken(token);

  const user = {
    id: userID,
    customerId: customerID,
    customerType: customerType,
    firstName: name,
    lastName: surname,
    fullName: `${name ?? ''} ${surname ?? ''}`.trim(),
    initials: `${name?.charAt(0) ?? ''} ${surname?.charAt(0) ?? ''}`.trim(),
    admin,
  };

  dispatch({type: SessionActionTypes.SET_USER, data: user});
  return Promise.resolve(user);

};

/**
 * Fetches the user role for the signed-in user.
 *
 * @param {Object} [args] The method parameters.
 * @param {boolean} [args.force = false] Should cache be invalidated?
 * @return {function(*, *): Promise}
 */
export const fetchUserRoleForUser = (args) => (dispatch, getState) => {

  const {force = false} = args ?? {};
  const userId = getState().session.user?.id;
  const url = `user-roles/by-user/${userId}`;

  if (!force && cache.has(url)) {
    return cache.get(url);
  }

  dispatch({type: SessionActionTypes.REQUEST});
  const request = HttpManager.get(url);
  cache.set(url, CacheLifetime.MEDIUM, request);

  return request
  .then((r) => {
    const {data} = r;
    const userRole = {...data, rights: new Set(data.rights ?? [])};
    dispatch({type: SessionActionTypes.SET_USER_ROLE, data: userRole});
    return {...r, data: userRole};
  })
  .catch((e) => {
    cache.delete(url);
    dispatch({type: SessionActionTypes.FAIL_REQUEST});
    throw e;
  });

};

/**
 * Fetches and sets the property access permissions for the signed-in user.
 *
 * @param {Object} [args] The method parameters.
 * @param {boolean} [args.force = false] Should cache be invalidated?
 * @return {function(*, *): Promise}
 */
export const fetchPropertyAccessForUser = (args) => (dispatch, getState) => {

  const {force = false} = args ?? {};
  const userId = getState().session.user?.id;
  const url = `users/propertyaccess/${userId}`;

  if (!force && cache.has(url)) {
    return cache.get(url);
  }

  dispatch({type: SessionActionTypes.REQUEST});
  const request = HttpManager.get(url);
  cache.set(url, CacheLifetime.MEDIUM, request);

  return request
  .then(r => {
    dispatch({
      type: SessionActionTypes.SET_PROPERTY_ACCESS,
      data: r.data ?? [],
    });
    return r;
  })
  .catch(e => {
    cache.delete(url);
    dispatch({type: SessionActionTypes.FAIL_REQUEST});
    throw e;
  });

};

/**
 * Fetches a list of actionable terms and conditions for the current user.
 * @param [args.userId=session.user.id] The user's ID.
 * @return {function(*,*): Promise}
 */
export const fetchActionableTermsAndConditionsForUser = (args) =>
    (dispatch, getState) => {
      const {userId = getState().session.user?.id} = args ?? {};
      dispatch({type: SessionActionTypes.REQUEST});
      return HttpManager.get(`regulations/${userId}`)
      .then(r => {
        dispatch({
          type: SessionActionTypes.SET_ACTIONABLE_TERMS_AND_CONDITIONS,
          data: r.data
        });
        return r;
      })
      .catch(e => {
        dispatch({type: SessionActionTypes.FAIL_REQUEST});
        throw e;
      });
    };

/**
 * Persists the status of one or more regulation's acceptance status for the
 * current user.
 * @param {Object[]} args.accepted A list of regulations, accepted by the
 * current user.
 * @return {function(*,*): Promise}
 */
export const acceptTermsAndConditions = args => dispatch => {
  dispatch({type: SessionActionTypes.REQUEST});
  return HttpManager.post('regulations', args.accepted)
  .then(r => {
    dispatch({
      type: SessionActionTypes.SET_ACTIONABLE_TERMS_AND_CONDITIONS,
      data: r.data,
    });
    return r;
  })
  .catch(e => {
    dispatch({type: SessionActionTypes.FAIL_REQUEST});
    throw e;
  });
};
