/*
 * Created by Paul Engelke on 18 March 2021.
 */

import {Box, TextField} from "@hti-ui/react-web-material";
import {useActions} from "@hti-ui/redux-core";
import {Button} from "@material-ui/core";
import PropTypes from 'prop-types';
import React, {useCallback, useState} from 'react';
import {useHistory} from "react-router-dom";
import {signInWithCredentials} from "../../actions/authActions";
import RouteNames from "../../constants/routeNames";
import useErrorHandler from "./useErrorHandler";

/**
 * The email/password login form for the Login Page.
 */
const LoginForm = props => {

  const {disabled} = props;
  const signIn = useActions(signInWithCredentials);

  const handleError = useErrorHandler();
  const history = useHistory();

  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const disableLoginButton = !emailAddress || !password;

  const onSignInClicked = useCallback(() => {
    signIn({emailAddress, password})
    .then(() => history.push(RouteNames.Root))
    .catch(e => handleError(e));
  }, [
    emailAddress, password, history,
    signIn, handleError,
  ]);

  const onEnterPressed = useCallback((e) => {
    if (e?.key === 'Enter' && !!emailAddress && !!password) {
      onSignInClicked();
    }
  }, [emailAddress, password, onSignInClicked]);

  return (<>

    <Box width={'100%'}>

      <Box bottomPadding={1}>
        <TextField
            label={'Email Address'}
            value={emailAddress}
            onChange={setEmailAddress}
            fullWidth
            onKeyDown={onEnterPressed}
        />
      </Box>

      <Box bottomPadding={2}>
        <TextField
            type={'password'}
            label={'Password'}
            value={password}
            onChange={setPassword}
            fullWidth
            onKeyDown={onEnterPressed}
        />
      </Box>

    </Box>

    <Box width={'100%'} alignItems={'center'}>
      <Button
          variant={'contained'}
          color={'secondary'}
          onClick={onSignInClicked}
          disabled={disabled || disableLoginButton}
      >Sign In</Button>
    </Box>

  </>);

};

LoginForm.propTypes = {
  disabled: PropTypes.bool.isRequired,
};

export default LoginForm;
