/*
 * Created by Paul Engelke on 17 March 2021.
 */

import {lazy} from "react";
import {makeStyles} from "@material-ui/core";
import {Redirect, Route, Switch} from "react-router-dom";
import RouteNames from "../../constants/routeNames";
import UserRight from "../../constants/security/userRights";
import ErrorPage from "../ErrorPage";
import PrivateRoute from "../PrivateRoute";
import NavigationBar from "./navigation/NavigationBar";
import WorkspaceBar from "./workspace/WorkspaceBar";
import PolicyConsentDialog from "./PolicyConsentDialog";

const AdditionalTaxesReportPage = lazy(
    () => import("../AdditionalTaxesReportPage"));
const ArmAccountTypePage = lazy(() => import("../ArmAccountTypePage"));
const ArmCompanyPage = lazy(() => import("../ArmCompanyPage"));
const ArmCompanyImportPage = lazy(() => import("../ArmCompanyImportPage"));
const ArmProfilePage = lazy(() => import("../ArmProfilePage"));
const ArmProfileImportPage = lazy(() => import("../ArmProfileImportPage"));
const ArmTemplatePdfPage = lazy(() => import("../ArmTemplatePdfPage"));
const ArmTemplateEmailPage = lazy(() => import("../ArmTemplateEmailPage"));
const BrandPage = lazy(() => import("../BrandPage"));
const BarcodePage = lazy(() => import("../BarcodePage"));
const CustomerDetailPage = lazy(() => import("../CustomerDetailPage"));
const CustomerLicencePage = lazy(() => import("../CustomerLicencePage"));
const CustomerPage = lazy(() => import("../CustomerPage"));
const CustomMenuPage = lazy(() => import("../CustomMenuPage"));
const DashboardPage = lazy(() => import("../DashboardPage"));
const DataExportPage = lazy(() => import("../DataExportPage"));
const DeliveryInterfacePage = lazy(() => import("../DeliveryInterfacePage"));
const DetailedPaymentAnalysisReportPage = lazy(
    () => import("../DetailedPaymentAnalysisReportPage"));
const ForexPaymentAnalysisReportPage = lazy(
    () => import("../ForexPaymentAnalysisReportPage"));
const DiscountPage = lazy(() => import("../DiscountPage"));
const PartialStockStoreSummaryReportPage = lazy(
    () => import("../PartialStockStoreSummaryReportPage"));
const ProductPusherReportPage = lazy(
    () => import("../ProductPusherReportPage"));
const ReasonPage = lazy(() => import("../ReasonPage"));
const DiscountTransactionReportPage = lazy(
    () => import("../DiscountTransactionReportPage"));
const ExternalGlobalStockDeliveryPage = lazy(
    () => import("../ExternalGlobalStockDeliveryPage"));
const ExternalGlobalStockOrderPage = lazy(
    () => import("../ExternalGlobalStockOrderPage"));
const ForexRatePage = lazy(() => import("../ForexRatePage"));
const GLExportPage = lazy(() => import("../GLExportPage"));
const GLExportSetupPage = lazy(() => import("../GLExportSetupPage"));
const GLMappingsPage = lazy(() => import("../GLMappingsPage"));
const GlobalStockDeliveryListingReportPage = lazy(
    () => import("../GlobalStockDeliveryListingReportPage"));
const GlobalStockOrderListingReportPage = lazy(
    () => import("../GlobalStockOrderListingReportPage"));
const InternalGlobalStockDeliveryPage = lazy(
    () => import("../InternalGlobalStockDeliveryPage"));
const InternalGlobalStockOrderPage = lazy(
    () => import("../InternalGlobalStockOrderPage"));
const OutletAnalysisReportPage = lazy(
    () => import("../OutletAnalysisReportPage"));
const OutletPage = lazy(() => import("../OutletPage"));
const PaymentAnalysisReportPage = lazy(
    () => import("../PaymentAnalysisReportPage"));
const PeriodicStockVarianceReportPage = lazy(
    () => import("../PeriodicStockVarianceReportPage"));
const PmsInterfacePage = lazy(() => import("../PmsInterfacePage"));
const PointOfSaleReportPage = lazy(() => import("../PointOfSaleReportPage"));
const ArmReportPage = lazy(() => import("../ArmReportPage"));
const AccountsReceivableTransactionReportPage = lazy(
    () => import("../AccountsReceivableTransactionReportPage"));
const PrinterPage = lazy(() => import("../PrinterPage"));
const ProductCategoryPage = lazy(() => import("../ProductCategoryPage"));
const ProductGroupPage = lazy(() => import("../ProductGroupPage"));
const ProductImportPage = lazy(() => import("../ProductImportPage"));
const ProductOptionMenuPage = lazy(() => import("../ProductOptionMenuPage"));
const ProductPage = lazy(() => import("../ProductPage"));
const PropertyAdminPage = lazy(() => import("../PropertyAdminPage"));
const PropertyCreationWizardPage = lazy(
    () => import("../PropertyCreationWizardPage"));
const PropertyPage = lazy(() => import("../PropertyPage"));
const RegisteredDevicePage = lazy(() => import("../RegisteredDevicePage"));
const SetMenuPage = lazy(() => import("../SetMenuPage"));
const ShiftPage = lazy(() => import("../ShiftPage"));
const StockCategorySummaryReportPage = lazy(
    () => import("../StockCategorySummaryReportPage"));
const StockCombinationManufacturePage = lazy(
    () => import("../StockCombinationManufacturePage"));
const StockCostCentrePage = lazy(() => import("../StockCostCentrePage"));
const StockDeliveryListingReportPage = lazy(
    () => import("../StockDeliveryListingReportPage"));
const StockDeliveryPage = lazy(() => import("../StockDeliveryPage"));
const StockDivisionManufacturePage = lazy(
    () => import("../StockDivisionManufacturePage"));
const StockGroupSummaryReportPage = lazy(
    () => import("../StockGroupSummaryReportPage"));
const StockInterfacePage = lazy(() => import("../StockInterfacePage"));
const StockIssueListingReportPage = lazy(
    () => import("../StockIssueListingReportPage"));
const StockIssuePage = lazy(() => import("../StockIssuePage"));
const StockItemActivityReportPage = lazy(
    () => import("../StockItemActivityReportPage"));
const StockManualSaleListingReportPage = lazy(
    () => import("../StockManualSaleListingReportPage"));
const StockManualSalePage = lazy(() => import("../StockManualSalePage"));
const StockMovementReportPage = lazy(
    () => import("../StockMovementReportPage"));
const StockOrderListingReportPage = lazy(
    () => import("../StockOrderListingReportPage"));
const StockOrderPage = lazy(() => import("../StockOrderPage"));
const StockOrderTemplatePage = lazy(() => import("../StockOrderTemplatePage"));
const StockRecipePage = lazy(() => import("../StockRecipePage"));
const StockRecipeReductionPage = lazy(
    () => import("../StockRecipeReductionPage"));
const StockReductionListingReportPage = lazy(
    () => import("../StockReductionListingReportPage"));
const StockReductionPage = lazy(() => import("../StockReductionPage"));
const StockReportPage = lazy(() => import("../StockReportPage"));
const StockRequisitionReportPage = lazy(
    () => import("../StockRequisitionListingReportPage"));
const StockRequisitionPage = lazy(() => import("../StockRequisitionPage"));
const StockStorePage = lazy(() => import("../StockStorePage"));
const StockStoreSummaryReportPage = lazy(
    () => import("../StockStoreSummaryReportPage"));
const StockSupplierPage = lazy(() => import("../StockSupplierPage"));
const StockTakeListingReportPage = lazy(
    () => import("../StockTakeListingReportPage"));
const StockTakePage = lazy(() => import("../StockTakePage"));
const StockValueReportPage = lazy(() => import("../StockValueReportPage"));
const StockVarianceReportPage = lazy(
    () => import("../StockVarianceReportPage"));
const TaxRulePage = lazy(() => import("../TaxRulePage"));
const TopSellersReportPage = lazy(() => import("../TopSellersReportPage"));
const TransactionAuditReportPage = lazy(
    () => import("../TransactionAuditReportPage"));
const UserActionHistoryPage = lazy(() => import("../UserActionHistoryPage"));
const UserAnalysisReportPage = lazy(() => import("../UserAnalysisReportPage"));
const UserPage = lazy(() => import("../UserPage"));
const UserRolePage = lazy(() => import("../UserRolePage"));
const PaymentTypePage = lazy(() => import("../PaymentTypePage"));
const TransactionTransferReportPage = lazy(
    () => import("../TransactionTransferrerReportPage"));
const StockReductionCategorySummaryReportPage = lazy(
    () => import("../StockReductionCategorySummaryReportPage"));
const StockScalePage = lazy(() => import("../StockScalePage"));
const UserLoginReportPage = lazy(() => import("../UserLoginReportPage"));
const DeliveryCostPriceIncreaseReportPage = lazy(
    () => import("../DeliveryCostPriceIncreaseReportPage"));
const PARLevelsReportPage = lazy(() => import("../PARLevelsReportPage"));
const CampaignPage = lazy(() => import("../CampaignPage"));
const SlowMovementReportPage = lazy(() => import("../SlowMovementReportPage"));
const AccountingExportReportPage = lazy(
    () => import("../AccountingExportReportPage"));
const ManualSalesImportPage = lazy(() => import("../ManualSalesImportPage"));
const StockRecipeCostPriceListingReportPage = lazy(
    () => import("../StockRecipeCostPriceListingReportPage"));

/**
 * The app's base component responsible for dictating the layout of it's
 * primary components, i.e. the navigation bar and viewable content area.
 *
 * It is also responsible for defining navigable routes.
 */
const App = () => {

  const classes = useStyles();

  return (<div className={classes.root}>

    <NavigationBar/>

    <div className={classes.contentAndWorkspace}>

      <WorkspaceBar/>

      <div className={classes.content}>
        <Switch>

          <PrivateRoute
              path={RouteNames.DashboardPage}
              exact
              component={DashboardPage}
          />

          <PrivateRoute
              path={RouteNames.CustomerPage}
              exact
              component={CustomerPage}
              userRights={[UserRight.ManageCustomers]}
          />

          <PrivateRoute
              path={RouteNames.PropertyAdminPage}
              exact
              component={PropertyAdminPage}
              userRights={[UserRight.PropertyAdministrator]}
          />

          <PrivateRoute
              path={RouteNames.CustomerLicencePage}
              exact
              component={CustomerLicencePage}
              userRights={[UserRight.HtiFullAccess]}
          />

          <PrivateRoute
              path={RouteNames.CustomerDetailPage}
              exact
              component={CustomerDetailPage}
              userRights={[UserRight.ManageAccount]}
          />

          <PrivateRoute
              path={RouteNames.UserPage}
              exact
              component={UserPage}
              userRights={[UserRight.ManageUsers]}
          />

          <PrivateRoute
              path={RouteNames.UserRolePage}
              exact
              component={UserRolePage}
              userRights={[UserRight.ManageUserRoles]}
          />

          <PrivateRoute
              path={RouteNames.PaymentTypePage}
              exact
              component={PaymentTypePage}
              userRights={[UserRight.ManagePaymentTypes]}
          />

          <PrivateRoute
              path={RouteNames.TaxRulePage}
              exact
              component={TaxRulePage}
              userRights={[UserRight.ManageTaxRules]}
          />

          <PrivateRoute
              path={RouteNames.DiscountPage}
              exact
              component={DiscountPage}
              userRights={[UserRight.ManageDiscounts]}
          />

          <PrivateRoute
              path={RouteNames.CampaignPage}
              exact
              component={CampaignPage}
              userRights={[UserRight.ManageCampaigns]}
          />

          <PrivateRoute
              path={RouteNames.ReasonPage}
              exact
              component={ReasonPage}
              userRights={[UserRight.ManageReasons]}
          />

          <PrivateRoute
              path={RouteNames.BrandPage}
              exact
              component={BrandPage}
              userRights={[UserRight.ManageBrands]}
          />

          <PrivateRoute
              path={RouteNames.BarcodePage}
              exact
              component={BarcodePage}
              userRights={[UserRight.ManageBarcodes]}
          />

          <PrivateRoute
              path={RouteNames.PropertyPage}
              exact
              component={PropertyPage}
              userRights={[UserRight.ManageProperties]}
          />

          <PrivateRoute
              path={RouteNames.ForexRatePage}
              exact
              component={ForexRatePage}
              userRights={[UserRight.ManageForexRates]}
          />

          <PrivateRoute
              path={RouteNames.PropertyCreationWizardPage}
              exact
              component={PropertyCreationWizardPage}
              userRights={[UserRight.ManageProperties]}
          />

          <PrivateRoute
              path={RouteNames.OutletPage}
              exact
              component={OutletPage}
              userRights={[UserRight.ManageOutlets]}
          />

          <PrivateRoute
              path={RouteNames.ShiftPage}
              exact
              component={ShiftPage}
              userRights={[UserRight.ManageShifts]}
          />

          <PrivateRoute
              path={RouteNames.ProductGroupPage}
              exact
              component={ProductGroupPage}
              userRights={[UserRight.ManageGroups]}
          />

          <PrivateRoute
              path={RouteNames.ProductCategoryPage}
              exact
              component={ProductCategoryPage}
              userRights={[UserRight.ManageCategories]}
          />

          <PrivateRoute
              path={RouteNames.ProductPage}
              exact
              component={ProductPage}
              userRights={[UserRight.ManageProducts]}
          />

          <PrivateRoute
              path={RouteNames.ProductImportPage}
              exact
              component={ProductImportPage}
              userRights={[UserRight.ManageProducts]}
          />

          <PrivateRoute
              path={RouteNames.ProductOptionMenuPage}
              exact
              component={ProductOptionMenuPage}
              userRights={[UserRight.ManageOptionMenus]}
          />

          <PrivateRoute
              path={RouteNames.CustomMenuPage}
              exact
              component={CustomMenuPage}
              userRights={[UserRight.ManageCustomMenus]}
          />

          <PrivateRoute
              path={RouteNames.SetMenuPage}
              exact
              component={SetMenuPage}
              userRights={[UserRight.ManageSetMenus]}
          />

          <PrivateRoute
              path={RouteNames.RegisteredDevicePage}
              exact
              component={RegisteredDevicePage}
              userRights={[UserRight.ManageDevices]}
          />

          <PrivateRoute
              path={RouteNames.PrinterPage}
              exact
              component={PrinterPage}
              userRights={[UserRight.ManagePrinters]}
          />

          <PrivateRoute
              path={RouteNames.StockStorePage}
              exact
              component={StockStorePage}
              userRights={[UserRight.ManageStores]}
          />

          <PrivateRoute
              path={RouteNames.StockSupplierPage}
              exact
              component={StockSupplierPage}
              userRights={[UserRight.ManageStockSuppliers]}
          />

          <PrivateRoute
              path={RouteNames.StockCostCentrePage}
              exact
              component={StockCostCentrePage}
              userRights={[UserRight.ManageStockCostCentres]}
          />

          <PrivateRoute
              path={RouteNames.StockOrderPage}
              exact
              component={StockOrderPage}
              userRights={[UserRight.ManageStockOrders]}
          />

          <PrivateRoute
              path={RouteNames.StockOrderTemplatePage}
              exact
              component={StockOrderTemplatePage}
              userRights={[UserRight.ManageStockOrderTemplates]}
          />

          <PrivateRoute
              path={RouteNames.InternalGlobalStockOrderPage}
              exact
              component={InternalGlobalStockOrderPage}
              userRights={[UserRight.ManageInternalGlobalStockOrders]}
          />

          <PrivateRoute
              path={RouteNames.ExternalGlobalStockOrderPage}
              exact
              component={ExternalGlobalStockOrderPage}
              userRights={[UserRight.ManageExternalGlobalStockOrders]}
          />

          <PrivateRoute
              path={RouteNames.StockDeliveryPage}
              exact
              component={StockDeliveryPage}
          />

          <PrivateRoute
              path={RouteNames.InternalGlobalStockDeliveryPage}
              exact
              component={InternalGlobalStockDeliveryPage}
              userRights={[UserRight.ManageInternalGlobalStockDeliveries]}
          />

          <PrivateRoute
              path={RouteNames.ExternalGlobalStockDeliveryPage}
              exact
              component={ExternalGlobalStockDeliveryPage}
              userRights={[UserRight.ManageExternalGlobalStockDeliveries]}
          />

          <PrivateRoute
              path={RouteNames.StockRequisitionPage}
              exact
              component={StockRequisitionPage}
              userRights={[UserRight.ManageStockRequisitions]}
          />

          <PrivateRoute
              path={RouteNames.StockIssuePage}
              exact
              component={StockIssuePage}
              userRights={[UserRight.ManageStockIssues]}
          />

          <PrivateRoute
              path={RouteNames.StockCombinationManufacturePage}
              exact
              component={StockCombinationManufacturePage}
              userRights={[UserRight.ManageStockCombinationManufactures]}
          />

          <PrivateRoute
              path={RouteNames.StockDivisionManufacturePage}
              exact
              component={StockDivisionManufacturePage}
              userRights={[UserRight.ManageStockDivisionManufactures]}
          />

          <PrivateRoute
              path={RouteNames.StockReductionPage}
              exact
              component={StockReductionPage}
              userRights={[UserRight.ManageStockReductions]}
          />

          <PrivateRoute
              path={RouteNames.StockRecipePage}
              exact
              component={StockRecipePage}
              userRights={[UserRight.ManageRecipes]}
          />

          <PrivateRoute
              path={RouteNames.StockRecipeReductionPage}
              exact
              component={StockRecipeReductionPage}
              userRights={[UserRight.ManageStockRecipeReductions]}
          />

          <PrivateRoute
              path={RouteNames.StockManualSalePage}
              exact
              component={StockManualSalePage}
              userRights={[UserRight.ManageStockManualSales]}
          />

          <PrivateRoute
              path={RouteNames.StockTakePage}
              exact
              component={StockTakePage}
              userRights={[UserRight.ManageStockCounts]}
          />

          <PrivateRoute
              path={RouteNames.StockInterfacePage}
              exact
              component={StockInterfacePage}
              userRights={[UserRight.HtiFullAccess]}
          />

          <PrivateRoute
              path={RouteNames.DeliveryInterfacePage}
              exact
              component={DeliveryInterfacePage}
              userRights={[UserRight.ManageUberEatsIntegration,
                UserRight.ManageBuzzIntegration]}
          />

          <PrivateRoute
              path={RouteNames.GlExportPage}
              exact
              component={GLExportPage}
              userRights={[UserRight.ManageGLExport]}
          />

          <PrivateRoute
              path={RouteNames.GlExportSetupPage}
              exact
              component={GLExportSetupPage}
              userRights={[UserRight.ManageGLExport]}
          />
          <PrivateRoute
              path={RouteNames.GlMappingsPage}
              exact
              component={GLMappingsPage}
              userRights={[UserRight.ManageGLExport]}
          />

          <PrivateRoute
              path={RouteNames.PmsInterfacePage}
              exact
              component={PmsInterfacePage}
              userRights={[UserRight.ManagePmsInterfaces]}
          />

          <PrivateRoute
              path={RouteNames.DataExportPage}
              exact
              component={DataExportPage}
              userRights={[UserRight.DataExport]}
          />

          <PrivateRoute
              path={RouteNames.UserActionHistoryPage}
              exact
              component={UserActionHistoryPage}
              userRights={[UserRight.UserActionHistory]}
          />

          <PrivateRoute
              path={RouteNames.PointOfSaleReportPage}
              exact
              component={PointOfSaleReportPage}
              userRights={[
                UserRight.TopSellersReport,
                UserRight.OutletAnalysisReport,
                UserRight.UserAnalysisReport,
                UserRight.TransactionAuditReport,
                UserRight.PaymentAnalysisReport,
                UserRight.DetailedPaymentAnalysisReport,
                UserRight.ForexPaymentAnalysisReport,
                UserRight.DiscountTransactionReport,
                UserRight.AdditionalTaxesReport,
                UserRight.TransactionTransferReport
              ]}
          />

          <PrivateRoute
              path={RouteNames.TopSellersReportPage}
              exact
              component={TopSellersReportPage}
              userRights={[UserRight.TopSellersReport]}
          />

          <PrivateRoute
              path={RouteNames.ProductPusherReportPage}
              exact
              component={ProductPusherReportPage}
              userRights={[UserRight.ProductPusherReport]}
          />

          <PrivateRoute
              path={RouteNames.OutletAnalysisReportPage}
              exact
              component={OutletAnalysisReportPage}
              userRights={[UserRight.OutletAnalysisReport]}
          />

          <PrivateRoute
              path={RouteNames.UserAnalysisReportPage}
              exact
              component={UserAnalysisReportPage}
              userRights={[UserRight.UserAnalysisReport]}
          />

          <PrivateRoute
              path={RouteNames.TransactionAuditReportPage}
              exact
              component={TransactionAuditReportPage}
              userRights={[UserRight.TransactionAuditReport]}
          />

          <PrivateRoute
              path={RouteNames.PaymentAnalysisReportPage}
              exact
              component={PaymentAnalysisReportPage}
              userRights={[UserRight.PaymentAnalysisReport]}
          />

          <PrivateRoute
              path={RouteNames.DetailedPaymentAnalysisReportPage}
              exact
              component={DetailedPaymentAnalysisReportPage}
              userRights={[UserRight.DetailedPaymentAnalysisReport]}
          />

          <PrivateRoute
              path={RouteNames.ForexPaymentAnalysisReportPage}
              exact
              component={ForexPaymentAnalysisReportPage}
              userRights={[UserRight.ForexPaymentAnalysisReport]}
          />

          <PrivateRoute
              path={RouteNames.DiscountTransactionReportPage}
              exact
              component={DiscountTransactionReportPage}
              userRights={[UserRight.DiscountTransactionReport]}
          />

          <PrivateRoute
              path={RouteNames.AdditionalTaxesReportPage}
              exact
              component={AdditionalTaxesReportPage}
              userRights={[UserRight.AdditionalTaxesReport]}
          />

          <PrivateRoute
              path={RouteNames.TransactionTransferReportPage}
              exact
              component={TransactionTransferReportPage}
              userRights={[UserRight.TransactionTransferReport]}
          />

          <PrivateRoute
              path={RouteNames.ArmReportPage}
              exact
              component={ArmReportPage}
              userRights={[
                UserRight.AccountsReceivableTransactionReport,
              ]}
          />

          <PrivateRoute
              path={RouteNames.AccountsReceivableTransactionReportPage}
              exact
              component={AccountsReceivableTransactionReportPage}
              userRights={[UserRight.AccountsReceivableTransactionReport]}
          />

          <PrivateRoute
              path={RouteNames.UserLoginReportPage}
              exact
              component={UserLoginReportPage}
              userRights={[UserRight.UserLoginReport]}
          />

          <PrivateRoute
              path={RouteNames.StockReportPage}
              exact
              component={StockReportPage}
              userRights={[
                UserRight.StockMovementReport,
                UserRight.StockStoreSummaryReport,
                UserRight.PartialStockStoreSummaryReport,
                UserRight.StockGroupSummaryReport,
                UserRight.StockCategorySummaryReport,
                UserRight.StockItemActivityReport,
                UserRight.StockValueReport,
                UserRight.StockVarianceReport,
                UserRight.StockDeliveriesReport,
                UserRight.StockOrdersReport,
                UserRight.StockRequisitionsReport,
                UserRight.StockIssuesReport,
                UserRight.StockReductionReport,
                UserRight.StockManualSalesReport,
                UserRight.StockTakeReport,
                UserRight.PeriodicStockVarianceReport,
                UserRight.GlobalStockOrderListingReport,
                UserRight.GlobalStockDeliveryListingReport,
              ]}
          />

          <PrivateRoute
              path={RouteNames.StockMovementReportPage}
              exact
              component={StockMovementReportPage}
              userRights={[UserRight.StockMovementReport]}
          />

          <PrivateRoute
              path={RouteNames.StockStoreSummaryReportPage}
              exact
              component={StockStoreSummaryReportPage}
              userRights={[UserRight.StockStoreSummaryReport]}
          />

          <PrivateRoute
              path={RouteNames.PartialStockStoreSummaryReportPage}
              exact
              component={PartialStockStoreSummaryReportPage}
              userRights={[UserRight.PartialStockStoreSummaryReport]}
          />

          <PrivateRoute
              path={RouteNames.StockReductionCategorySummaryReportPage}
              exact
              component={StockReductionCategorySummaryReportPage}
              userRights={[UserRight.StockReductionCategorySummaryReport]}
          />

          <PrivateRoute
              path={RouteNames.StockGroupSummaryReportPage}
              exact
              component={StockGroupSummaryReportPage}
              userRights={[UserRight.StockGroupSummaryReport]}
          />

          <PrivateRoute
              path={RouteNames.StockCategorySummaryReportPage}
              exact
              component={StockCategorySummaryReportPage}
              userRights={[UserRight.StockCategorySummaryReport]}
          />

          <PrivateRoute
              path={RouteNames.StockItemActivityReportPage}
              exact
              component={StockItemActivityReportPage}
              userRights={[UserRight.StockItemActivityReport]}
          />

          <PrivateRoute
              path={RouteNames.StockValueReportPage}
              exact
              component={StockValueReportPage}
              userRights={[UserRight.StockValueReport]}
          />

          <PrivateRoute
              path={RouteNames.StockVarianceReportPage}
              exact
              component={StockVarianceReportPage}
              userRights={[UserRight.StockVarianceReport]}
          />

          <PrivateRoute
              path={RouteNames.StockDeliveryListingReportPage}
              exact
              component={StockDeliveryListingReportPage}
              userRights={[UserRight.StockDeliveriesReport]}
          />

          <PrivateRoute
              path={RouteNames.StockOrderListingReportPage}
              exact
              component={StockOrderListingReportPage}
              userRights={[UserRight.StockOrdersReport]}
          />

          <PrivateRoute
              path={RouteNames.GlobalStockOrderListingReportPage}
              exact
              component={GlobalStockOrderListingReportPage}
              userRights={[UserRight.GlobalStockOrderListingReport]}
          />

          <PrivateRoute
              path={RouteNames.GlobalStockDeliveryListingReportPage}
              exact
              component={GlobalStockDeliveryListingReportPage}
              userRights={[UserRight.GlobalStockDeliveryListingReport]}
          />

          <PrivateRoute
              path={RouteNames.StockRequisitionListingReportPage}
              exact
              component={StockRequisitionReportPage}
              userRights={[UserRight.StockRequisitionsReport]}
          />

          <PrivateRoute
              path={RouteNames.StockIssueListingReportPage}
              exact
              component={StockIssueListingReportPage}
              userRights={[UserRight.StockIssuesReport]}
          />

          <PrivateRoute
              path={RouteNames.StockReductionListingReportPage}
              exact
              component={StockReductionListingReportPage}
              userRights={[UserRight.StockReductionReport]}
          />

          <PrivateRoute
              path={RouteNames.StockManualSaleListingReportPage}
              exact
              component={StockManualSaleListingReportPage}
              userRights={[UserRight.StockManualSalesReport]}
          />

          <PrivateRoute
              path={RouteNames.StockTakeListingReportPage}
              exact
              component={StockTakeListingReportPage}
              userRights={[UserRight.StockTakeReport]}
          />

          <PrivateRoute
              path={RouteNames.PeriodicStockVarianceReportPage}
              exact
              component={PeriodicStockVarianceReportPage}
              userRights={[UserRight.PeriodicStockVarianceReport]}
          />

          <PrivateRoute
              path={RouteNames.StockScalePage}
              exact
              component={StockScalePage}
              userRights={[UserRight.ManageStockScales]}
          />

          <PrivateRoute
              path={RouteNames.DeliveryCostPriceIncreaseReportPage}
              exact
              component={DeliveryCostPriceIncreaseReportPage}
              userRights={[UserRight.DeliveryCostPriceIncreaseReport]}
          />

          <PrivateRoute
              path={RouteNames.PARLevelsReportPage}
              exact
              component={PARLevelsReportPage}
              userRights={[UserRight.PARLevelsReport]}
          />

          <PrivateRoute
              path={RouteNames.SlowMovementReportPage}
              exact
              component={SlowMovementReportPage}
              userRights={[UserRight.SlowMovementReport]}
          />

          <PrivateRoute
              path={RouteNames.StockRecipeCostPriceListingReportPage}
              exact
              component={StockRecipeCostPriceListingReportPage}
              userRights={[UserRight.StockRecipeCostPriceListingReport]}
          />

          <PrivateRoute
              path={RouteNames.ArmAccountTypePage}
              exact
              component={ArmAccountTypePage}
              userRights={[UserRight.ManageAccountTypes]}
          />

          <PrivateRoute
              path={RouteNames.ArmCompanyPage}
              exact
              component={ArmCompanyPage}
              userRights={[UserRight.ManageArmCompanies,
                UserRight.ManageCompanies]}
          />

          <PrivateRoute
              path={RouteNames.ArmCompanyImportPage}
              exact
              component={ArmCompanyImportPage}
              userRights={[UserRight.ManageArmCompanies,
                UserRight.ManageCompanies]}
          />

          <PrivateRoute
              path={RouteNames.ManualSalesImportPage}
              exact
              component={ManualSalesImportPage}
              userRights={[UserRight.ManualSalesImport]}
          />

          <PrivateRoute
              path={RouteNames.ArmProfilePage}
              exact
              component={ArmProfilePage}
              userRights={[UserRight.ManageArmProfiles,
                UserRight.ManageProfiles]}
          />

          <PrivateRoute
              path={RouteNames.ArmProfileImportPage}
              exact
              component={ArmProfileImportPage}
              userRights={[UserRight.ManageArmProfiles,
                UserRight.ManageProfiles]}
          />

          <PrivateRoute
              path={RouteNames.ArmTemplatePdfPage}
              exact
              component={ArmTemplatePdfPage}
              userRights={[UserRight.ManageAccountTypes]}
          />

          <PrivateRoute
              path={RouteNames.ArmTemplateEmailPage}
              exact
              component={ArmTemplateEmailPage}
              userRights={[UserRight.ManageAccountTypes]}
          />

          <PrivateRoute
              path={RouteNames.AccountingExportReportPage}
              exact
              component={AccountingExportReportPage}
              userRights={[UserRight.ManageGLExport]}
          />

          <Route path={RouteNames.ErrorPage} component={ErrorPage}/>

          <Redirect to={RouteNames.DashboardPage}/>

        </Switch>
      </div>

    </div>

    <PolicyConsentDialog/>

  </div>);

};

const useStyles = makeStyles({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
  },
  contentAndWorkspace: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',
  },
  content: {
    flex: 1,
    overflow: 'hidden',
  },
});

export default App;
