/*
 * Created by Paul Engelke on 09 April 2020.
 */

/**
 * A utility for object-related functionality, where native functionality is
 * lacking or can be simplified.
 */
export default class ObjectUtility {

  /**
   * Retrieves a property from the given object, using a dot-separated path to
   * the property.
   *
   * @param {*} object The object, whose property will be retrieved.
   * @param {string} path A property path for the desired value.
   * @param {*} [defaultValue] A default value, if the value is missing or
   * inaccessible.
   * @return {*}
   */
  static get(object, path, defaultValue) {
    if (object == null) {
      return defaultValue;
    }
    const value = path.split('.').reduce((object, subKey) => {
      return object == null ? undefined : object[subKey];
    }, object);
    return value == null ? defaultValue : value;
  }

  // noinspection JSUnusedGlobalSymbols
  /**
   * Performs a shallow comparison of the two given objects.
   *
   * @param o1 The first object.
   * @param o2 The second object to be compared to the first.
   * @return {boolean}
   */
  static shallowEquals(o1, o2) {

    if (ObjectUtility.isNull(o1, o2)) {
      return false;
    }

    let o1v = Object.keys(o1);
    let o2v = Object.keys(o2);

    if (o1v.length !== o2v.length) {
      return false;
    }

    for (let key of o1v) {
      if (o2[key] !== o1[key]) {
        return false;
      }
    }

    return true;

  }

  /**
   * Determines if any of the given objects are null.
   *
   * @param {...object} objects A list of objects to be evaluated.
   * @return {boolean}
   */
  static isNull(...objects) {
    for (let o of objects) {
      if (o == null) {
        return true;
      }
    }
    return false;
  }

  // noinspection JSUnusedGlobalSymbols
  /**
   * Checks if any of the given values can be considered 'falsy'.
   *
   * @param {...*} values A set of values to evaluate.
   * @return {boolean}
   * @see https://developer.mozilla.org/en-US/docs/Glossary/Falsy
   */
  static isFalsy(...values) {
    for (const v of values) {
      if (!v) {
        return true;
      }
    }
    return false;
  }

  /**
   * Recursively freezes an object hierarchy.
   * @param {*} o An object-like entity.
   * @return {*}
   */
  static deepFreeze(o) {

    const properties = Object.getOwnPropertyNames(o);
    for (const property of properties) {
      const value = o[property];
      if (!!value && typeof value === "object") {
        this.deepFreeze(value);
      }
    }

    return Object.freeze(o);

  }

  /**
   * Creates a new object from `o`, where only the given `keys` are included.
   * @param {Object} o The object that contains the desired keys.
   * @param {string[]} keys A list of desired keys.
   * @return {Object}
   */
  static selectKeys(o, keys) {

    if (!Array.isArray(keys) || keys.length === 0 || o == null) {
      return {};
    }

    const _keys = new Set(keys);
    return Object.entries(o).reduce((m, [k,v]) => {
      if (_keys.has(k)) {
        m[k] = v;
      }
      return m;
    }, {});

  }

}
