/*
 * Created by Paul Engelke on 15 January 2020.
 */

import {CustomerType} from "../customerType";
import Module from "./modules";
import RightCategory from "./rightCategories";

/**
 * A complete set of user rights used for authorizing access to parts of the
 * system.
 *
 * The 'id' is used to identity these rights when evaluating a user's
 * authorization level.
 *
 * All other properties for each right are used for UI purposes.
 * The 'hidden' property indicates that the user right should not be visible to
 * users.
 */
const UserRight = Object.freeze({

  HtiFullAccess: {
    id: 'HtiFullAccess',
    module: Module.Base,
    category: RightCategory.Hti,
    hidden: true,
    label: 'Full Access',
    description: 'Grants access to all aspects of the NebulaPOS system.',
  },

  ManageAccount: {
    id: 'ManageAccount',
    module: Module.Base,
    category: RightCategory.AccountManagement,
    label: 'Manage Account',
    description: 'Grants the ability to modify account details.',
  },

  ManageBrands: {
    id: 'ManageBrands',
    module: Module.Base,
    category: RightCategory.AccountManagement,
    label: 'Manage Brands',
    description: 'Grants the ability to add, modify and remove brands.',
  },

  ManageBarcodes: {
    id: 'ManageBarcodes',
    module: Module.Base,
    category: RightCategory.PointOfSaleManagement,
    label: 'Manage Barcodes',
    description: 'Grants the ability to add, modify and remove barcodes.',
  },

  ManageCustomers: {
    id: 'ManageCustomers',
    module: Module.Base,
    customerTypes: [CustomerType.HTI, CustomerType.VAR_PARTNER],
    category: RightCategory.CustomerManagement,
    label: 'Manage Customers',
    description: 'Grants the ability to add and modify customers.',
  },

  PropertyAdministrator: {
    id: 'PropertyAdministrator',
    module: Module.Base,
    customerTypes: [CustomerType.HTI, CustomerType.VAR_PARTNER],
    category: RightCategory.CustomerManagement,
    label: 'Property Administrator',
    description: 'Grants the ability to perform system cleanups, re-open shifts etc..',
  },

  ManageProperties: {
    id: 'ManageProperties',
    module: Module.Base,
    category: RightCategory.AccountManagement,
    label: 'Manage Properties',
    description: 'Grants the ability to add, modify and remove properties.',
  },

  ManageOutlets: {
    id: 'ManageOutlets',
    module: Module.Base,
    category: RightCategory.AccountManagement,
    label: 'Manage Outlets',
    description: 'Grants the ability to add, modify and remove outlets.',
  },

  ManageUsers: {
    id: 'ManageUsers',
    module: Module.Base,
    category: RightCategory.AccountManagement,
    label: 'Manage Users',
    description: 'Grants the ability to add, modify and remove users.',
  },

  ManageUserRoles: {
    id: 'ManageUserRoles',
    module: Module.Base,
    category: RightCategory.AccountManagement,
    label: 'Manage User Roles',
    description: 'Grants the ability to add, modify and remove user roles.',
  },

  ManagePaymentTypes: {
    id: 'ManagePaymentTypes',
    module: Module.Base,
    category: RightCategory.AccountManagement,
    label: 'Manage Payment Types',
    description: 'Grants the ability to add, modify and remove payment types.',
  },

  ManageTaxRules: {
    id: 'ManageTaxRules',
    module: Module.Base,
    category: RightCategory.AccountManagement,
    label: 'Manage Tax Rules',
    description: 'Grants the ability to add, modify and remove tax rules.',
  },

  ManageDevices: {
    id: 'ManageDevices',
    module: Module.Base,
    category: RightCategory.PointOfSaleManagement,
    label: 'Manage Devices',
    description: 'Grants the ability to add, modify and remove mobile devices.',
  },

  ManagePrinters: {
    id: 'ManagePrinters',
    module: Module.Base,
    category: RightCategory.PointOfSaleManagement,
    label: 'Manage Printers',
    description: 'Grants the ability to add, modify and remove printers.',
  },

  ManageShifts: {
    id: 'ManageShifts',
    module: Module.Base,
    category: RightCategory.PointOfSaleManagement,
    label: 'Manage Shifts',
    description: 'Grants the ability to add, modify and remove shifts.',
  },

  ManageGroups: {
    id: 'ManageGroups',
    module: Module.Base,
    category: RightCategory.PointOfSaleManagement,
    label: 'Manage Groups',
    description: 'Grants the ability to add, modify and remove product groups.',
  },

  ManageCategories: {
    id: 'ManageCategories',
    module: Module.Base,
    category: RightCategory.PointOfSaleManagement,
    label: 'Manage Categories',
    description: 'Grants the ability to add, modify and remove product '
        + 'categories.',
  },

  ManageProducts: {
    id: 'ManageProducts',
    label: 'Manage Products',
    module: Module.Base,
    category: RightCategory.PointOfSaleManagement,
    description: 'Grants the ability to add, modify and remove products.',
  },

  EditProductNames: {
    id: 'EditProductNames',
    module: Module.Base,
    category: RightCategory.PointOfSaleManagement,
    label: 'Edit Product Names',
    description: 'Grants the ability to change a product\'s name.',
  },

  ManageOptionMenus: {
    id: 'ManageOptionMenus',
    module: Module.Base,
    category: RightCategory.PointOfSaleManagement,
    label: 'Manage Option Menus',
    description: 'Grants the ability to add, modify and remove product option '
        + 'menus.',
  },

  ManageCustomMenus: {
    id: 'ManageCustomMenus',
    module: Module.CustomMenus,
    category: RightCategory.PointOfSaleManagement,
    label: 'Manage Custom Menus',
    description: 'Grants the ability to add, modify and remove custom menus.',
  },

  ManageSetMenus: {
    id: 'ManageSetMenus',
    module: Module.SetMenus,
    category: RightCategory.PointOfSaleManagement,
    label: 'Manage Set Menus',
    description: 'Grants the ability to add, modify and remove set menus.',
  },

  ManagePmsInterfaces: {
    id: 'ManagePmsInterfaces',
    module: Module.Base,
    category: RightCategory.Interfaces,
    label: 'Manage PMS Interfaces',
    description: 'Grants the ability to add, modify and remove PMS interface '
        + 'configurations.',
  },

  ManageUberEatsIntegration: {
    id: 'ManageUberEatsIntegration',
    module: Module.UberEatsIntegration,
    category: RightCategory.Interfaces,
    label: 'Manage Uber Eats Integration',
    description: 'Grants the ability to add, modify and remove Uber Eats '
        + 'interface configurations.',
  },

  ManageBuzzIntegration: {
    id: 'ManageBuzzIntegration',
    module: Module.BuzzIntegration,
    category: RightCategory.Interfaces,
    label: 'Manage Buzz Integration',
    description: 'Grants the ability to add, modify and remove Buzz '
        + 'interface configurations.',
  },

  ManageGLExport: {
    id: 'ManageGLExport',
    module: Module.GLExport,
    category: RightCategory.Interfaces,
    label: 'Manage GL Export Setups and GL Mappings',
    description: 'Grants the ability to add, modify and remove GL Export Setups '
        + 'and GL Mapping codes.',
  },

  DataExport: {
    id: 'DataExport',
    module: Module.Base,
    category: RightCategory.AccountManagement,
    label: 'Data Export',
    description: 'Grants the ability to export data',
  },

  ManageDiscounts: {
    id: 'ManageDiscounts',
    module: Module.Base,
    category: RightCategory.PointOfSaleManagement,
    label: 'Manage Discounts',
    description: 'Grants the ability to add, modify and remove discounts.',
  },

  ManageReasons: {
    id: 'ManageReasons',
    module: Module.Base,
    category: RightCategory.PointOfSaleManagement,
    label: 'Manage Reasons',
    description: 'Grants the ability to add, modify and remove reasons for transfers and voids.',
  },

  OutletAnalysisReport: {
    id: 'OutletAnalysisReport',
    module: Module.Base,
    category: RightCategory.Reports,
    label: 'Outlet Analysis Report',
    description: 'Grants access to the Outlet Analysis report.',
  },

  TopSellersReport: {
    id: 'TopSellersReport',
    module: Module.Base,
    category: RightCategory.Reports,
    label: 'Top Sellers Report',
    description: 'Grants access to the Top Sellers report.',
  },

  ProductPusherReport: {
    id: "ProductPusherReport",
    module: Module.Base,
    category: RightCategory.Reports,
    label: 'Product Pusher Report',
    description: 'Grants access to the Product Pusher report.',
  },

  UserAnalysisReport: {
    id: 'UserAnalysisReport',
    module: Module.Base,
    category: RightCategory.Reports,
    label: 'User Analysis Report',
    description: 'Grants access to the User Analysis report.',
  },

  TransactionAuditReport: {
    id: 'TransactionAuditReport',
    module: Module.Base,
    category: RightCategory.Reports,
    label: 'Transaction Audit Report',
    description: 'Grants access to the Transaction Audit report.',
  },

  PaymentAnalysisReport: {
    id: 'PaymentAnalysisReport',
    module: Module.Base,
    category: RightCategory.Reports,
    label: 'Payment Analysis Report',
    description: 'Grants access to the Payment Analysis report.',
  },

  DetailedPaymentAnalysisReport: {
    id: 'DetailedPaymentAnalysisReport',
    module: Module.Base,
    category: RightCategory.Reports,
    label: 'Detailed Payment Analysis Report',
    description: 'Grants access to the Detailed Payment Analysis report.',
  },

  ForexPaymentAnalysisReport: {
    id: 'ForexPaymentAnalysisReport',
    module: Module.Base,
    category: RightCategory.Reports,
    label: 'Forex Payment Analysis Report',
    description: 'Grants access to the Forex Payment Analysis report.',
  },

  DiscountTransactionReport: {
    id: 'DiscountTransactionReport',
    module: Module.Base,
    category: RightCategory.Reports,
    label: 'Discount Transaction Report',
    description: 'Grants access to the Discount Transaction report.',
  },

  AdditionalTaxesReport: {
    id: 'AdditionalTaxesReport',
    module: Module.Base,
    category: RightCategory.Reports,
    label: 'Additional Taxes Report',
    description: 'Grants access to the Additional Taxes report.',
  },

  TransactionTransferReport: {
    id: 'TransactionTransferReport',
    module: Module.Base,
    category: RightCategory.Reports,
    label: 'Transaction Transfer Report',
    description: 'Grants access to the Transaction Transfer report.',
  },

  AccountsReceivableTransactionReport: {
    id: 'AccountsReceivableTransactionReport',
    module: Module.Base,
    category: RightCategory.Reports,
    label: 'Accounts Receivable Transaction Report',
    description: 'Grants access to the Accounts Receivable Transaction report.',
  },

  ChangeInvoiceUsers: {
    id: 'ChangeInvoiceUsers',
    module: Module.Base,
    category: RightCategory.MobileApp,
    label: 'Change Invoice Users',
    description: 'Grants the ability to re-assign invoices.',
  },

  ManageInvoices: {
    id: 'ManageInvoices',
    module: Module.Base,
    category: RightCategory.MobileApp,
    label: 'Manage Invoices',
    description: 'Grants the ability to view all invoices for the current '
        + 'shift.',
  },

  ReopenInvoices: {
    id: 'ReopenInvoices',
    module: Module.Base,
    category: RightCategory.MobileApp,
    label: 'Reopen Invoices',
    description: 'Grants the ability to reopen closed invoices.',
  },

  ReprintInvoices: {
    id: 'ReprintInvoices',
    module: Module.Base,
    category: RightCategory.MobileApp,
    label: 'Reprint Invoices',
    description: 'Grants the ability to reprint invoices.',
  },

  ReprintNebulaPayReceipts: {
    id: 'ReprintNebulaPayReceipts',
    module: Module.Base,
    category: RightCategory.MobileApp,
    label: 'Reprint NebulaPAY Receipts',
    description: 'Grants the ability to reprint NebulaPAY receipts.',
  },

  AccessMobileDashboard: {
    id: 'AccessMobileDashboard',
    module: Module.Base,
    category: RightCategory.MobileApp,
    label: 'Access Mobile Dashboard',
    description: 'Grants access to the dashboard screen on the mobile app.',
  },

  ViewShiftTotals: {
    id: 'ViewShiftTotals',
    module: Module.Base,
    category: RightCategory.MobileApp,
    label: 'View Shift Totals',
    description: 'Grants the ability to print shift totals.',
  },

  ViewSalesMix: {
    id: 'ViewSalesMix',
    module: Module.Base,
    category: RightCategory.MobileApp,
    label: 'View Sales Mix',
    description: 'Grants the ability to print a sales mix.',
  },

  CloseShifts: {
    id: 'CloseShifts',
    module: Module.Base,
    category: RightCategory.MobileApp,
    label: 'Close Shifts',
    description: 'Grants the ability to close shifts.',
  },

  TransferInvoiceTransactions: {
    id: 'TransferInvoiceTransactions',
    module: Module.Base,
    category: RightCategory.MobileApp,
    label: 'Transfer Invoice Transactions',
    description: 'Grants the ability to transfer transactions from one invoice '
        + 'to another invoice in the same outlet.',
  },

  TransferInvoiceTransactionsBetweenOutlets: {
    id: 'TransferInvoiceTransactionsBetweenOutlets',
    module: Module.Base,
    category: RightCategory.MobileApp,
    label: 'Transfer Invoice Transactions Between Outlets',
    description: 'Grants the ability to transfer transactions from one invoice '
        + 'to another invoice in a different outlet.',
  },

  VoidInvoiceTransactions: {
    id: 'VoidInvoiceTransactions',
    module: Module.Base,
    category: RightCategory.MobileApp,
    label: 'Void Invoice Transactions',
    description: 'Grants the ability to void an invoice\'s transactions.',
  },

  AuthorizeCustomPayments: {
    id: 'AuthorizeCustomPayments',
    module: Module.Base,
    category: RightCategory.MobileApp,
    label: 'Authorize Custom Payments',
    description: 'Grants the ability to authorize custom payments where the '
        + 'payment type has been flagged to require authorization.',
  },

  ReverseInvoicePayments: {
    id: 'ReverseInvoicePayments',
    module: Module.Base,
    category: RightCategory.MobileApp,
    label: 'Reverse Invoice Payments',
    description: 'Grants the ability to reverse payments made for an invoice.',
  },

  SkipPatronSignature: {
    id: 'SkipPatronSignature',
    module: Module.Base,
    category: RightCategory.MobileApp,
    label: 'Skip Patron Signature',
    description: 'Grants the ability to skip the patron signature step for '
        + 'room charges.',
  },

  ApplyInvoiceDiscounts: {
    id: 'ApplyInvoiceDiscounts',
    module: Module.Base,
    category: RightCategory.MobileApp,
    label: 'Apply Invoice Discounts',
    description: 'Grants the ability to apply discounts to invoices and their '
        + 'transactions.',
  },

  ReverseInvoiceDiscounts: {
    id: 'ReverseInvoiceDiscounts',
    module: Module.Base,
    category: RightCategory.MobileApp,
    label: 'Reverse Invoice Discounts',
    description: 'Grants the ability to remove discounts from invoices and '
        + 'their transactions.',
  },

  ChargeGratuity: {
    id: 'ChargeGratuity',
    module: Module.Base,
    category: RightCategory.MobileApp,
    label: 'Charge Gratuity',
    description: 'Grants the ability to add a gratuity charge to an invoice.',
  },

  ChargeAdditionalCharge: {
    id: 'ChargeAdditionalCharge',
    module: Module.Base,
    category: RightCategory.MobileApp,
    label: 'Charge Additional Charges',
    description: 'Grants the ability to add an additional charge to an invoice.',
  },

  ReverseAdditionalCharge: {
    id: 'ReverseAdditionalCharge',
    module: Module.Base,
    category: RightCategory.MobileApp,
    label: 'Reverse Additional Charges',
    description:
        'Grants the ability to reverse an additional charge for an invoice.',
  },

  OpenCashDrawers: {
    id: 'OpenCashDrawers',
    module: Module.Base,
    category: RightCategory.MobileApp,
    label: 'Open Cash Drawers',
    description: 'Grants the ability to open cash drawers on demand.',
  },

  CorrectInvoiceTransactions: {
    id: 'CorrectInvoiceTransactions',
    module: Module.Base,
    category: RightCategory.MobileApp,
    label: 'Correct Invoice Transactions',
    description: 'Grants the ability to correct invoice transactions.',
  },

  CreateInvoices: {
    id: 'CreateInvoices',
    module: Module.Base,
    category: RightCategory.MobileApp,
    label: 'Create Invoices',
    description: 'Grants the ability to create new invoices.',
  },

  EditInvoices: {
    id: 'EditInvoices',
    module: Module.Base,
    category: RightCategory.MobileApp,
    label: 'Edit Invoices',
    description: 'Grants the ability to edit an invoice\'s details.',
  },

  ViewInvoices: {
    id: 'ViewInvoices',
    module: Module.Base,
    category: RightCategory.MobileApp,
    label: 'View Invoices',
    description: 'Grants the ability to view invoices created by the user.',
  },

  ReprintRequisition: {
    id: 'ReprintRequisition',
    module: Module.Base,
    category: RightCategory.MobileApp,
    label: 'Reprint Requisition',
    description: 'Grants the ability to reprint a requisition from an '
        + 'invoice\'s requisition history.',
  },

  SetCustomSellingPrice: {
    id: 'SetCustomSellingPrice',
    module: Module.Base,
    category: RightCategory.MobileApp,
    label: 'Set Custom Selling Price',
    description: 'Grants the ability to set a custom selling price for '
        + 'products that have a 0.00 price. The custom price is set on the '
        + 'Mobile App when ordering the product.',
  },

  ApplyTaxExemptionsOnAnInvoice: {
    id: 'ApplyTaxExemptionsOnAnInvoice',
    module: Module.Base,
    category: RightCategory.MobileApp,
    label: 'Apply Tax Exemptions On An Invoice',
    description: 'Grants the ability to remove VAT and or additional taxes on an invoice.',
  },

  CaptureProfilePayments: {
    id: 'CaptureProfilePayments',
    module: Module.Base,
    category: RightCategory.MobileApp,
    label: 'Capture Profile Payments',
    description: 'Grants the ability to capture a payment for an accounts receivable profile',
  },

  ReverseProfilePayments: {
    id: 'ReverseProfilePayments',
    module: Module.Base,
    category: RightCategory.MobileApp,
    label: 'Reverse Profile Payments',
    description: 'Grants the ability to reverse a payment made on an accounts receivable profile',
  },

  ManageStores: {
    id: 'ManageStores',
    module: Module.StockManagement,
    category: RightCategory.StockManagement,
    label: 'Manage Stores',
    description: 'Grants the ability to add, modify and remove stores for '
        + 'stock.',
  },

  ManageStockSuppliers: {
    id: 'ManageStockSuppliers',
    module: Module.StockManagement,
    category: RightCategory.StockManagement,
    label: 'Manage Suppliers',
    description: 'Grants the ability to manage stock suppliers.',
  },

  ManageStockCostCentres: {
    id: 'ManageStockCostCentres',
    module: Module.StockManagement,
    category: RightCategory.StockManagement,
    label: 'Manage Cost Centres',
    description: 'Grants the ability to manage stock cost centres.',
  },

  ManageStockOrders: {
    id: 'ManageStockOrders',
    module: Module.StockManagement,
    category: RightCategory.StockManagement,
    label: 'Manage Stock Orders',
    description: 'Grants the ability to manage standard stock orders for a '
        + 'property.',
  },

  ManageStockOrderTemplates: {
    id: 'ManageStockOrderTemplates',
    module: Module.StockManagement,
    category: RightCategory.StockManagement,
    label: 'Manage Stock Order Templates',
    description: 'Grants the ability to manage stock order templates for a '
        + 'property.',
  },

  ManageInternalGlobalStockOrders: {
    id: 'ManageInternalGlobalStockOrders',
    module: Module.GlobalStockManagement,
    category: RightCategory.StockManagement,
    label: 'Manage Global Stock Orders (Internal)',
    description: 'Grants the ability to manage stock orders for one of your '
        + 'properties\' stock, from other properties belonging to your account.',
  },

  ManageExternalGlobalStockOrders: {
    id: 'ManageExternalGlobalStockOrders',
    module: Module.SupplierStockManagement,
    category: RightCategory.StockManagement,
    label: 'Manage Global Stock Orders (External)',
    description: 'Grants the ability to manage stock orders from other '
        + 'NebulaPOS customers, for one of your properties\' stock.',
  },

  ManageStockDeliveries: {
    id: 'ManageStockDeliveries',
    module: Module.StockManagement,
    category: RightCategory.StockManagement,
    label: 'Manage Stock Deliveries',
    description: 'Grants the ability to manage standard stock deliveries.',
  },

  ManageInternalGlobalStockDeliveries: {
    id: 'ManageInternalGlobalStockDeliveries',
    module: Module.GlobalStockManagement,
    category: RightCategory.StockManagement,
    label: 'Manage Global Stock Deliveries (Internal)',
    description: 'Grants the ability to manage stock deliveries linked to '
        + 'internal global stock orders.',
  },

  ManageExternalGlobalStockDeliveries: {
    id: 'ManageExternalGlobalStockDeliveries',
    module: Module.SupplierStockManagement,
    category: RightCategory.StockManagement,
    label: 'Manage Global Stock Deliveries (External)',
    description: 'Grants the ability to manage stock deliveries linked to '
        + 'external orders.',
  },

  ManageStockRequisitions: {
    id: 'ManageStockRequisitions',
    module: Module.StockManagement,
    category: RightCategory.StockManagement,
    label: 'Manage Requisitions',
    description: 'Grants the ability to manage stock requisitions.',
  },

  ManageStockIssues: {
    id: 'ManageStockIssues',
    module: Module.StockManagement,
    category: RightCategory.StockManagement,
    label: 'Manage Issues',
    description: 'Grants the ability to manage stock issues.',
  },

  ManageStockCombinationManufactures: {
    id: 'ManageStockCombinationManufactures',
    module: Module.StockManufacturing,
    category: RightCategory.StockManagement,
    label: 'Manage Stock Manufacturing (Combination)',
    description: 'Grants the ability to manage combination manufacturing.',
  },

  ManageStockDivisionManufactures: {
    id: 'ManageStockDivisionManufactures',
    module: Module.StockManufacturing,
    category: RightCategory.StockManagement,
    label: 'Manage Stock Manufacturing (Division)',
    description: 'Grants the ability to manage division manufacturing.',
  },

  ManageStockReductions: {
    id: 'ManageStockReductions',
    module: Module.StockManagement,
    category: RightCategory.StockManagement,
    label: 'Manage Reductions',
    description: 'Grants the ability to manage stock reductions.',
  },

  ManageStockRecipeReductions: {
    id: 'ManageStockRecipeReductions',
    module: Module.StockManagement,
    category: RightCategory.StockManagement,
    label: 'Manage Recipe Reductions',
    description: 'Grants the ability to manage stock recipe reductions.',
  },

  ManageStockManualSales: {
    id: 'ManageStockManualSales',
    module: Module.StockManagement,
    category: RightCategory.StockManagement,
    label: 'Manage Manual Sales',
    description: 'Grants the ability to manage manual sales for stock.',
  },

  ManageStockCounts: {
    id: 'ManageStockCounts',
    module: Module.StockManagement,
    category: RightCategory.StockManagement,
    label: 'Manage Stock-takes',
    description: 'Grants the ability to manage stock-takes.',
  },

  ViewStockLevels: {
    id: "ViewStockLevels",
    module: Module.StockManagement,
    category: RightCategory.MobileApp,
    label: "View Stock Levels",
    description: 'Grants the ability to view stock levels from the mobile app.',
  },

  ConfirmStockOrders: {
    id: 'ConfirmStockOrders',
    module: Module.StockManagement,
    category: RightCategory.StockManagement,
    label: 'Confirm Stock Orders',
    description: 'Grants the ability to confirm stock orders.',
  },

  ConfirmStockDeliveries: {
    id: 'ConfirmStockDeliveries',
    module: Module.StockManagement,
    category: RightCategory.StockManagement,
    label: 'Confirm Stock Deliveries',
    description: 'Grants the ability to confirm stock deliveries.',
  },

  ConfirmInternalGlobalStockDeliveries: {
    id: 'ConfirmInternalGlobalStockDeliveries',
    module: Module.GlobalStockManagement,
    category: RightCategory.StockManagement,
    label: 'Confirm Global Stock Deliveries (Internal)',
    description: 'Grants the ability to confirm internal global stock deliveries.',
  },

  ConfirmExternalGlobalStockDeliveries: {
    id: 'ConfirmExternalGlobalStockDeliveries',
    module: Module.SupplierStockManagement,
    category: RightCategory.StockManagement,
    label: 'Confirm Global Stock Deliveries (External)',
    description: 'Grants the ability to confirm external global stock deliveries.',
  },

  ConfirmStockRequisitions: {
    id: 'ConfirmStockRequisitions',
    module: Module.StockManagement,
    category: RightCategory.StockManagement,
    label: 'Confirm Stock Requisitions',
    description: 'Grants the ability to confirm stock requisitions.',
  },

  ConfirmStockIssues: {
    id: 'ConfirmStockIssues',
    module: Module.StockManagement,
    category: RightCategory.StockManagement,
    label: 'Confirm Stock Issues',
    description: 'Grants the ability to confirm stock issues.',
  },

  ConfirmStockCombinationManufactures: {
    id: 'ConfirmStockCombinationManufactures',
    module: Module.StockManufacturing,
    category: RightCategory.StockManagement,
    label: 'Confirm Stock Manufactures (Combination)',
    description: 'Grants the ability to confirm combination manufactures.',
  },

  ConfirmStockDivisionManufactures: {
    id: 'ConfirmStockDivisionManufactures',
    module: Module.StockManufacturing,
    category: RightCategory.StockManagement,
    label: 'Confirm Stock Manufactures (Division)',
    description: 'Grants the ability to confirm division manufactures.',
  },

  ConfirmStockManualSales: {
    id: 'ConfirmStockManualSales',
    module: Module.StockManagement,
    category: RightCategory.StockManagement,
    label: 'Confirm Stock Manual Sales',
    description: 'Grants the ability to confirm manual sales.',
  },

  ConfirmStockReductions: {
    id: 'ConfirmStockReductions',
    module: Module.StockManagement,
    category: RightCategory.StockManagement,
    label: 'Confirm Stock Reductions',
    description: 'Grants the ability to confirm stock reductions.',
  },

  ConfirmStockRecipeReductions: {
    id: 'ConfirmStockRecipeReductions',
    module: Module.StockManagement,
    category: RightCategory.StockManagement,
    label: 'Confirm Stock Recipe Reductions',
    description: 'Grants the ability to confirm stock recipe reductions.',
  },

  ConfirmStockTakes: {
    id: 'ConfirmStockTakes',
    module: Module.StockManagement,
    category: RightCategory.StockManagement,
    label: 'Confirm Stock Takes',
    description: 'Grants the ability to confirm stock stakes.',
  },

  DownloadUnconfirmedStockDocuments: {
    id: 'DownloadUnconfirmedStockDocuments',
    module: Module.StockManagement,
    category: RightCategory.StockManagement,
    label: 'Download Unconfirmed Stock Documents',
    description: 'Grants the ability to download stock documents that have not been confirmed.',
  },

  EditCostPrices: {
    id: 'EditCostPrices',
    module: Module.StockManagement,
    category: RightCategory.StockManagement,
    label: 'Edit Cost Prices',
    description: 'Grants the ability to change cost prices.',
  },

  EditHistoricalCostPrices: {
    id: 'EditHistoricalCostPrices',
    module: Module.StockManagement,
    category: RightCategory.StockManagement,
    label: 'Edit Historical Cost Prices',
    description: 'Grants the ability to change historical cost prices.',
  },

  ManageAccountTypes: {
    id: 'ManageAccountTypes',
    module: Module.Arm,
    category: RightCategory.Arm,
    label: 'Manage Account Types',
    description: 'Grants the ability to manage account types and levels.',
  },

  ManageArmCompanies: {
    id: 'ManageArmCompanies',
    module: Module.Arm,
    category: RightCategory.Arm,
    label: 'Manage Companies',
    description: 'Grants the ability to manage companies.',
  },

  ManageCompanies: {
    id: 'ManageCompanies',
    module: Module.InvoiceToGeneralLedger,
    category: RightCategory.InvoiceToGeneralLedger,
    label: 'Manage Companies',
    description: 'Grants the ability to manage companies.',
  },

  ManualSalesImport: {
    id: 'ManualSalesImport',
    module: Module.ManualSales,
    category: RightCategory.Interfaces,
    label: 'Manual Sales Import',
    description: 'Grants the ability to import manual sales.',
  },

  ManageArmProfiles: {
    id: 'ManageArmProfiles',
    module: Module.Arm,
    category: RightCategory.Arm,
    label: 'Manage Profiles',
    description: 'Grants the ability to manage profiles.',
  },

  ManageProfiles: {
    id: 'ManageProfiles',
    module: Module.InvoiceToGeneralLedger,
    category: RightCategory.InvoiceToGeneralLedger,
    label: 'Manage Profiles',
    description: 'Grants the ability to manage profiles.',
  },

  PublishStockData: {
    id: 'PublishStockData',
    module: Module.StockManagement,
    hidden: true,
    category: RightCategory.Publishing,
    label: 'Publish Stock Data',
    description: 'Grants the ability to publish changes to stock data.',
  },

  StockDeliveriesReport: {
    id: 'StockDeliveriesReport',
    module: Module.StockManagement,
    category: RightCategory.Reports,
    label: 'Stock Deliveries Report',
    description: 'Grants access to the Stock Deliveries report.',
  },

  StockIssuesReport: {
    id: 'StockIssuesReport',
    module: Module.StockManagement,
    category: RightCategory.Reports,
    label: 'Stock Issues Report',
    description: 'Grants access to the Stock Issues report.',
  },

  StockItemActivityReport: {
    id: 'StockItemActivityReport',
    module: Module.StockManagement,
    category: RightCategory.Reports,
    label: 'Stock Item Activity Report',
    description: 'Grants access to the Stock Item Activity report.',
  },

  RequestStockViaMobileApp: {
    id: 'RequestStockViaMobileApp',
    module: Module.StockManagement,
    category: RightCategory.MobileApp,
    label: 'Request Stock',
    description: 'Grants the ability to request stock via the mobile app.',
  },

  StockMovementReport: {
    id: 'StockMovementReport',
    module: Module.StockManagement,
    category: RightCategory.Reports,
    label: 'Stock Movement Report',
    description: 'Grants access to the Stock Movement report.',
  },

  StockNonItemReductionReport: {
    id: 'StockNonItemReductionReport',
    module: Module.StockManagement,
    hidden: true,
    category: RightCategory.Reports,
    label: 'Non-item Reduction Stock Report',
    description: 'Grants access to the Non-item Reduction Stock report.',
  },

  StockOrdersReport: {
    id: 'StockOrdersReport',
    module: Module.StockManagement,
    category: RightCategory.Reports,
    label: 'Stock Order Listing Report',
    description: 'Grants access to the Stock Order Listing Report.',
  },

  GlobalStockOrderListingReport: {
    id: 'GlobalStockOrderListingReport',
    module: Module.GlobalStockManagement,
    category: RightCategory.Reports,
    label: 'Global Stock Order Listing Report',
    description: 'Grants access to the Global Stock Order Listing Report.',
  },

  GlobalStockDeliveryListingReport: {
    id: 'GlobalStockDeliveryListingReport',
    module: Module.GlobalStockManagement,
    category: RightCategory.Reports,
    label: 'Global Stock Delivery Listing Report',
    description: 'Grants access to the Global Stock Delivery Listing Report.',
  },

  StockReductionCategorySummaryReport: {
    id: "StockReductionCategorySummaryReport",
    module: Module.StockManagement,
    category: RightCategory.Reports,
    label: "Stock Reduction Category Summary Report",
    description: "Grants access to the Stock Reduction Category Summary Report.",
  },

  ParStockReport: {
    id: 'ParStockReport',
    module: Module.StockManagement,
    hidden: true,
    category: RightCategory.Reports,
    label: 'Par Stock Report',
    description: 'Grants access to the Par Stock report.',
  },

  StockReductionReport: {
    id: 'StockReductionReport',
    module: Module.StockManagement,
    category: RightCategory.Reports,
    label: 'Stock Reduction Report',
    description: 'Grants access to the Stock Reduction report.',
  },

  StockManualSalesReport: {
    id: 'StockManualSalesReport',
    module: Module.StockManagement,
    category: RightCategory.Reports,
    label: 'Stock Manual Sales Report',
    description: 'Grants access to the Stock Manual Sales report.',
  },

  StockRequisitionsReport: {
    id: 'StockRequisitionsReport',
    module: Module.StockManagement,
    category: RightCategory.Reports,
    label: 'Stock Requisitions Report',
    description: 'Grants access to the Stock Requisitions report.',
  },

  StockTakeReport: {
    id: 'StockTakeReport',
    module: Module.StockManagement,
    category: RightCategory.Reports,
    label: 'Stock Take Report',
    description: 'Grants access to the Stock Take report.',
  },

  DeliveryCostPriceIncreaseReport: {
    id: 'DeliveryCostPriceIncreaseReport',
    module: Module.StockManagement,
    category: RightCategory.Reports,
    label: 'Delivery Cost Price Increase Report',
    description: 'Grants access to the Delivery Cost Price Increase report.',
  },

  PARLevelsReport: {
    id: 'PARLevelsReport',
    module: Module.StockManagement,
    category: RightCategory.Reports,
    label: 'PAR Levels Report',
    description: 'Grants access to the PAR Levels report.',
  },

  StockStoreSummaryReport: {
    id: 'StockStoreSummaryReport',
    module: Module.StockManagement,
    category: RightCategory.Reports,
    label: 'Store Summary Report',
    description: 'Grants access to the Store Summary report.',
  },

  PartialStockStoreSummaryReport: {
    id: 'PartialStockStoreSummaryReport',
    module: Module.StockManagement,
    category: RightCategory.Reports,
    label: 'Partial Store Summary Report',
    description: 'Grants access to the Partial Store Summary report.',
  },

  StockGroupSummaryReport: {
    id: 'StockGroupSummaryReport',
    module: Module.StockManagement,
    category: RightCategory.Reports,
    label: 'Group Summary Report',
    description: 'Grants access to the Group Summary report.',
  },

  StockCategorySummaryReport: {
    id: 'StockCategorySummaryReport',
    module: Module.StockManagement,
    category: RightCategory.Reports,
    label: 'Category Summary Report',
    description: 'Grants access to the Category Summary report.',
  },

  SupplierHistoryStockReport: {
    id: 'SupplierHistoryStockReport',
    module: Module.StockManagement,
    hidden: true,
    category: RightCategory.Reports,
    label: 'Supplier History Stock Report',
    description: 'Grants access to the Supplier History Stock report.',
  },

  StockValueReport: {
    id: 'StockValueReport',
    module: Module.StockManagement,
    category: RightCategory.Reports,
    label: 'Stock Value Report',
    description: 'Grants access to the Stock Value report.',
  },

  StockVarianceReport: {
    id: 'StockVarianceReport',
    module: Module.StockManagement,
    category: RightCategory.Reports,
    label: 'Stock Variance Report',
    description: 'Grants access to the Stock Variance report.',
  },

  ManageRecipes: {
    id: 'ManageRecipes',
    module: Module.StockManagement,
    category: RightCategory.StockManagement,
    label: 'Manage Recipes',
    description: 'Grants the ability to manage recipes.',
  },

  PeriodicStockVarianceReport: {
    id: 'PeriodicStockVarianceReport',
    module: Module.StockManagement,
    category: RightCategory.Reports,
    label: 'Periodic Stock Variance Report',
    description: 'Grants access to the Periodic Stock Variance report.',
  },

  ManageExternalPosOrders: {
    id: 'ManageExternalPosOrders',
    module: Module.PosOrders,
    category: RightCategory.MobileApp,
    label: 'Manage External Orders',
    description: 'Allows the user to manage external orders and grants access '
        + 'to the Order Manager app mode.',
  },

  ManageBumpScreen: {
    id: 'ManageBumpScreen',
    module: Module.PosOrders,
    category: RightCategory.MobileApp,
    label: 'Manage Bump Screen',
    description: "Grants access to the Bump Screen app mode and allows the user to affect the preparation status of orders.",
  },

  LimitedBumpScreen: {
    id: 'LimitedBumpScreen',
    module: Module.PosOrders,
    category: RightCategory.MobileApp,
    label: 'Limited Bump Screen',
    description: "Grants access to the Bump Screen app mode for view purposes and completing items only. Order status changes require the 'Manage Bump Screen' right.",
  },

  CancelPosOrder: {
    id: 'CancelPosOrder',
    module: Module.PosOrders,
    category: RightCategory.MobileApp,
    label: 'Cancel Order',
    description: 'Allows the user to cancel a POS order from the Order '
        + 'Manager.',
  },

  RefundItems: {
    id: 'RefundItems',
    module: Module.Base,
    category: RightCategory.MobileApp,
    label: 'Refund Items',
    description: 'Allows the user to create invoices to refund items.',
  },

  ManageGlobalAppPreferences: {
    id: 'ManageGlobalAppPreferences',
    module: Module.Base,
    category: RightCategory.MobileApp,
    label: 'Manage Global App Preferences',
    description: 'Allows the user to set global app preferences per device in the mobile app.',
  },

  UserActionHistory: {
    id: 'UserActionHistory',
    module: Module.Base,
    category: RightCategory.Reports,
    label: 'User Action History',
    description: 'Grants access to user action history reporting.',
  },

  UserLoginReport: {
    id: 'UserLoginReport',
    module: Module.Base,
    category: RightCategory.Reports,
    label: 'User Login Report',
    description: 'Grants access to user login reporting.',
  },

  RetailProductSearch: {
    id: "RetailProductSearch",
    module: Module.RetailPos,
    category: RightCategory.MobileApp,
    label: "Product Search (Retail)",
    description: "Grants access to search functionality for products when the mobile app is in retail mode.",
  },

  ManageStockScales: {
    id: 'ManageStockScales',
    module: Module.StockScales,
    category: RightCategory.StockManagement,
    label: 'Manage Stock Scales',
    description: 'Grants the ability to add, modify and remove stock scales.',
  },

  ManageForexRates: {
    id: 'ManageForexRates',
    module: Module.Base,
    category: RightCategory.AccountManagement,
    label: 'Manage Forex Rates',
    description: "Allows the user to set foreign exchange rates for a property.",
  },

  ManageCampaigns:{
    id: "ManageCampaigns",
    module: Module.Campaigns,
    category: RightCategory.PointOfSaleManagement,
    label: "Manage Campaigns",
    description: "Grants the ability to add, modify, and remove campaigns for a property.",
  },

  ApplyInvoiceCampaignsManually:{
    id: "ApplyInvoiceCampaignsManually",
    module: Module.Campaigns,
    category: RightCategory.MobileApp,
    label: "Apply Campaign Discounts Manually",
    description: "Grants the ability to apply campaigns at user discretion, overriding scheduling with certain limitations.",
  },

  SellOutOfStockProducts:{
    id: "SellOutOfStockProducts",
    module: Module.Base,
    category: RightCategory.MobileApp,
    label: "Sell Out of Stock Products",
    description: "Grants the ability to sell products that have been marked as out of stock at user discretion.",
  },

  SlowMovementReport: {
    id: 'SlowMovementReport',
    module: Module.StockManagement,
    category: RightCategory.Reports,
    label: 'Slow Movement Report',
    description: 'Grants access to the Slow Movement report.',
  },

  StockRecipeCostPriceListingReport: {
    id: 'StockRecipeCostPriceListingReport',
    module: Module.StockManagement,
    category: RightCategory.Reports,
    label: 'Recipe Cost Price Listing Report',
    description: 'Grants access to the Recipe Cost Price Listing report.',
  },

});

export default UserRight;
