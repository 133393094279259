/*
 * Created by Paul Engelke on 11 January 2022.
 */

import {Page, PageContent} from "@hti-ui/react-web-material";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  makeStyles,
  Typography
} from "@material-ui/core";
import {useCallback} from "react";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";
import {useImageAssets} from "../../assets/images";
import AppStatusCode from "../../constants/codes/appStatusCodes";
import {
  ArrowBackRounded as BackToAppIcon,
  ContactSupportRounded as SupportIcon,
  ErrorOutline as ErrorIcon
} from "@material-ui/icons";
import RouteNames from "../../constants/routeNames";

/**
 * A page for app error descriptions.
 */
const ErrorPage = () => {

  const {t} = useTranslation(["ErrorPage", "error"]);
  const ImageAssets = useImageAssets();
  const classes = useStyles();

  const history = useHistory();
  const {error} = useParams();

  const defaultName = t(`error:${AppStatusCode.Unknown}.Name`);
  const errorName = t(`error:${error}.Name`, defaultName);
  const defaultDescription = t(`error:${AppStatusCode.Unknown}.Description`);
  const errorDescription = t(`error:${error}.Description`, defaultDescription);

  const onBackToPosClicked = useCallback(() => {
    history.push(RouteNames.Root);
  }, []);

  const onContactSupportClicked = useCallback(() => {
    window.open(
        "https://helpdesk.htihospitality.tech/portal/en/kb/articles/contact-hti-support",
        "_blank"
    );
  }, []);

  // noinspection JSCheckFunctionSignatures,JSValidateTypes
  return (<Page>

    <PageContent classes={{wrapper: classes.pageContent}}>

      <img
          className={classes.productLogo}
          alt={'nebula-pos-logo'}
          src={ImageAssets.PosLogoHorizontal}
      />

      <Card className={classes.errorRoot}>

        <CardContent>

          <div className={classes.errorHeader}>

            <Avatar classes={{root: classes.errorAvatar}}>
              <ErrorIcon/>
            </Avatar>

            <Typography className={classes.errorName}>{errorName}</Typography>

          </div>

          <Typography className={classes.errorDescription}>
            {errorDescription}
          </Typography>

        </CardContent>

        <CardActions>

          <Button variant={"outlined"} onClick={onBackToPosClicked}>
            <BackToAppIcon/>
            {t("ErrorPage:Button.NebulaPOS")}
          </Button>
          <Button variant={"outlined"} onClick={onContactSupportClicked}>
            <SupportIcon/>
            {t("ErrorPage:Button.ContactSupport")}
          </Button>

        </CardActions>

      </Card>

    </PageContent>

  </Page>);

};

const useStyles = makeStyles(theme => ({
  pageContent: {
    paddingTop: theme.spacing(5),
    alignItems: "center",
  },
  productLogo: {
    width: "30%",
    minWidth: 300,
    marginBottom: theme.spacing(5),
  },
  errorRoot: {
    width: "100%",
    maxWidth: 500,
  },
  errorAvatar: {
    backgroundColor: theme.palette.custom.red,
    marginRight: theme.spacing(),
  },
  errorHeader: {
    display: "flex",
    alignItems: "center",
  },
  errorName: {
    ...theme.typography.h5,
  },
  errorDescription: {
    ...theme.typography.body1,
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(2),
  },
}));

export default ErrorPage;
