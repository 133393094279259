/*
 * Created by Paul Engelke on 13 August 2021.
 */

import {DeviceHubRounded as InterfaceSettingsIcon} from "@material-ui/icons";
import RouteNames from "../../../../constants/routeNames";
import UserRight from "../../../../constants/security/userRights";
import DrawerMenuItem from "../DrawerMenuItem";

/**
 * Creates a navigation node for the navigation drawer's tree that pertains to
 * functionality related to first- and third-party integration.
 */
const InterfaceNode = ({goTo, t}) => ({
  id: "Interfaces",
  label: t("core:Term.Interfaces"),
  quickLink: {IconComponent: InterfaceSettingsIcon},
  renderItem: node => (<DrawerMenuItem key={node.id} node={node}/>),
  children: [

    {
      id: RouteNames.DeliveryInterfacePage,
      label: t("core:Term.DeliveryInterfaces"),
      onClick: goTo(RouteNames.DeliveryInterfacePage),
      security: {
        rights: [UserRight.ManageUberEatsIntegration,
          UserRight.ManageBuzzIntegration], requireAllRights: false
      },
    },

    {
      id: RouteNames.GlExportSetupPage,
      label: t("core:Term.GlExportSetup"),
      onClick: goTo(RouteNames.GlExportSetupPage),
      security: {rights: [UserRight.ManageGLExport]},
    },

    {
      id: RouteNames.GlMappingsPage,
      label: t("core:Term.GlMappings"),
      onClick: goTo(RouteNames.GlMappingsPage),
      security: {rights: [UserRight.ManageGLExport]},
    },

    {
      id: RouteNames.PmsInterfacePage,
      label: t("core:Term.PmsInterfaces"),
      onClick: goTo(RouteNames.PmsInterfacePage),
      security: {rights: [UserRight.ManagePmsInterfaces]},
    },
    {
      id: RouteNames.ManualSalesImportPage,
      label: t("core:Term.ManualSalesImport"),
      onClick: goTo(RouteNames.ManualSalesImportPage),
      security: {rights: [UserRight.ManualSalesImport]},
    },

  ],
});

export default InterfaceNode;
