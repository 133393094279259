/*
 * Created by Paul Engelke on 23 March 2021.
 */

import {
  CacheLifetime,
  LocalCache,
  LocalCacheRegistry
} from "@hti-ui/local-cache";
import PropertyActionTypes from "../constants/action-types/propertyActionTypes";
import HttpManager from "../utils/httpManager";

const cache = new LocalCache();
LocalCacheRegistry.register(cache);

/**
 * Fetches a list properties for a given customer.
 *
 * @param {object} [args] The request parameters.
 * @param {number} [args.customerId=workspace.customer.id] The customer ID.
 * @param {boolean} [args.force] Should we invalidate cache and force data
 * to be fetched?
 * @return {function(*,*): Promise}
 */
export const fetchProperties = (args) => (dispatch, getState) => {

  const defaultCustomerId = getState().workspace.customer?.id;
  const {customerId = defaultCustomerId, force = false} = args ?? {};
  const url = `properties/${customerId}`;

  if (!force && cache.has(url)) {
    return cache.get(url);
  }

  dispatch({type: PropertyActionTypes.REQUEST});
  const request = HttpManager.get(url);

  cache.clear();
  cache.set(url, CacheLifetime.LONG, request);

  return request
  .then(r => {
    dispatch({type: PropertyActionTypes.SET, data: r.data})
    return r;
  })
  .catch(e => {
    dispatch({type: PropertyActionTypes.FAIL_REQUEST});
    throw e;
  });

};

/**
 * Fetches a property for a given property ID.
 *
 * @param {object} [args] The request parameters.
 * @param {number} [args.customerId=workspace.customer.id] The customer ID.
 * @param {number} [args.propertyId=workspace.property.id] The property ID.
 * to be fetched?
 * @return {function(*,*): Promise}
 */
export const fetchProperty = (args) => (dispatch, getState) => {

  const defaultCustomerId = getState().workspace.customer?.id;
  const defaultPropertyId = getState().workspace.property?.id;
  const {
    customerId = defaultCustomerId,
    propertyId = defaultPropertyId
  } = args ?? {};

  const url = `properties/${customerId}/${propertyId}`;

  dispatch({type: PropertyActionTypes.REQUEST});
  const request = HttpManager.get(url);
  return request
  .then(r => {
    dispatch({type: PropertyActionTypes.UPDATE, data: r.data})
    return r;
  })
  .catch(e => {
    dispatch({type: PropertyActionTypes.FAIL_REQUEST});
    throw e;
  });

};

/**
 * Creates a new property and its outlets.
 *
 * @param {Object} args.property The new property.
 * @param {Object[]} args.outlets A list of new outlets for the new property.
 * @return {function(*,*): Promise}
 */
export const createProperty = (args) => (dispatch) => {
  const {property, outlets} = args;
  dispatch({type: PropertyActionTypes.REQUEST});
  return HttpManager.post('properties', {...property, outlets})
  .then(r => {
    dispatch({type: PropertyActionTypes.CREATE, data: r.data});
    return r;
  })
  .catch(e => {
    dispatch({type: PropertyActionTypes.FAIL_REQUEST});
    throw e;
  });
};

/**
 * Updates an existing property configuration.
 *
 * @param {Object} args The updated property.
 * @return {function(*,*): Promise}
 */
export const updateProperty = (args) => (dispatch) => {
  dispatch({type: PropertyActionTypes.REQUEST});
  return HttpManager.put(`properties/${args.id}`, args)
  .then(r => {
    dispatch({type: PropertyActionTypes.UPDATE, data: r.data});
    return r;
  })
  .catch(e => {
    dispatch({type: PropertyActionTypes.FAIL_REQUEST});
    throw e;
  });
};

/**
 * Captures a base64image for the given property.
 * @param {Object} args The updated property.
 * @return {function(*,*): Promise}
 */
export const capturePropertyImage = (args) => (dispatch, getState) => {

  const customerID = getState().workspace?.customer?.id;

  dispatch({type: PropertyActionTypes.REQUEST});
  return HttpManager.put(`properties/image/capture`,
      {...args, customerID})
  .then(r => {
    dispatch({type: PropertyActionTypes.UPDATE, data: r.data});
    return r;
  })
  .catch(e => {
    dispatch({type: PropertyActionTypes.FAIL_REQUEST});
    throw e;
  });
};

/**
 * Deletes the image for the given property.
 *
 * @param {String} args.uri The URI to query an image from cloud storage
 * @return {function(*,*): Promise}
 */
export const deletePropertyImage = (args) => (dispatch) => {

  dispatch({type: PropertyActionTypes.REQUEST});
  return HttpManager.put(`properties/image/delete`, args)
  .then(r => {
    dispatch({type: PropertyActionTypes.UPDATE, data: r.data});
    return r;
  })
  .catch(e => {
    dispatch({type: PropertyActionTypes.FAIL_REQUEST});
    throw e;
  });
};

/**
 * Updates an existing property configuration.
 *
 * @param {number} args.propertyId The property ID.
 * @return {function(*,*): Promise}
 */
export const deleteProperty = (args) => (dispatch) => {
  const {propertyId} = args;
  dispatch({type: PropertyActionTypes.REQUEST});
  return HttpManager.delete(`properties/${propertyId}`)
  .then(r => {
    dispatch({type: PropertyActionTypes.DELETE, data: {id: propertyId}});
    return r;
  })
  .catch(e => {
    dispatch({type: PropertyActionTypes.FAIL_REQUEST});
    throw e;
  });
};

/**
 * Fetches a list properties for a given customer and bypasses the redux state.
 * In some cases you need to get a list of properties for a customer and not
 * update the redux state example the customer license screen.
 *
 * @param {object} [args] The request parameters.
 * @param {number} [args.customerId=workspace.customer.id] The customer ID.
 * @param {boolean} [args.force] Should we invalidate cache and force data
 * to be fetched?
 * @return {function(*,*): Promise}
 */
export const fetchPropertiesBypassRedux = (args) => (dispatch) => {

  const {customerId} = args ?? {};
  const url = `properties/${customerId}`;

  dispatch({type: PropertyActionTypes.REQUEST});
  const request = HttpManager.get(url);

  return request
  .then(r => {
    dispatch({type: PropertyActionTypes.COMPLETE_REQUEST})
    return r;
  })
  .catch(e => {
    dispatch({type: PropertyActionTypes.FAIL_REQUEST});
    throw e;
  });

};
