/*
 * Created by Paul Engelke on 19 July 2021.
 */

import {makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";

/**
 * The navigation drawer footer component.
 */
const DrawerFooter = () => {

  const classes = useStyles();
  const {t} = useTranslation(["glossary", "App"]);

  return (<div className={classes.root}>

    <a
        target="_blank" rel="noreferrer"
        href="https://www.htihospitality.tech"
        className={classes.link}
    >{t("App:Navigation.Drawer.Footer.DevelopedBy")}</a>

    <p>|</p>

    <a
        target="_blank" rel="noreferrer"
        href="https://www.htihospitality.tech/nebulapos-privacy-policy-2020/"
        className={classes.link}
    >{t("glossary:PrivacyPolicy")}</a>

    <p>|</p>

    <a
        target="_blank" rel="noreferrer"
        href="https://helpdesk.htihospitality.tech/portal/en/kb/articles/contact-hti-support"
        className={classes.link}
    >{t("glossary:Support")}</a>

    <p>|</p>

    <a
        href="/redoc.html"
        target="_blank" rel="noopener noreferrer"
        className={classes.link}
    >{t("glossary:APIDocs")}</a>

  </div>);

};

const useStyles = makeStyles(theme => ({
  root: {

    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(.5),
    paddingTop: theme.spacing(1),

    color: theme.palette.text.secondary,
    fontSize: theme.typography.caption.fontSize,

    borderTop: `1px solid #303030`,

  },
  link: {
    margin: theme.spacing(.5),
    color: theme.palette.text.secondary,
    textDecoration: "none",
    "&:hover": {textDecoration: "underline"},
  },
}));

export default DrawerFooter;
