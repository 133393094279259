/*
 * Created by Paul Engelke on 14 December 2021.
 */

import {useCallback} from "react";
import AppStatusCode from "../../constants/codes/appStatusCodes";
import useGlobalMessenger from "../../hooks/useGlobalMessenger";

/**
 * An error handler for the Login Page.
 * @return {function(*):void}
 */
const useErrorHandler = () => {
  const {dispatchErrorMessage} = useGlobalMessenger();
  return useCallback(e => {
    switch (e._code) {
      case AppStatusCode.Unauthenticated:
        dispatchErrorMessage(
            "No matching user account was found. Please try again. "
            + "If you do not have an account, "
            + "then you will need to create one first.");
        break;
      case AppStatusCode.UserAccountDeactivated:
        dispatchErrorMessage(
            "Your user account has been deactivated. "
            + "Please contact your organization's system administrator.");
        break;
      case AppStatusCode.CustomerAccountDeactivated:
        dispatchErrorMessage(
            "Your customer account has been deactivated. "
            + "Please contact HTI support for assistance.");
        break;
      default:
        dispatchErrorMessage("Sign in failed.");
        break;
    }
  }, [dispatchErrorMessage]);
};

export default useErrorHandler;
