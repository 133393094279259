/*
 * Created by Paul Engelke on 26 March 2021.
 */

import {StateUtility} from "@hti-ui/redux-core";
import CategoryActionTypes from "../constants/action-types/categoryActionTypes";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";

const DEFAULT_STATE = StateUtility.State({data: []}, {async: true});

/**
 * A redux state reducer for managing a list of categories.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case CategoryActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case CategoryActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case CategoryActionTypes.SET:
      return StateUtility.defaultSet(state, action.data);

    case CategoryActionTypes.MERGE:
      return merge(state, action);

    case CategoryActionTypes.CREATE:
      return create(state, action);

    case CategoryActionTypes.UPDATE:
      return update(state, action);

    case CategoryActionTypes.DELETE:
      return _delete(state, action);

    case CategoryActionTypes.DELETE_SUBCATEGORY:
      return deleteSubcategory(state, action);

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;

  }

};

/**
 * Merges a list of categories from the server into the local set.
 */
const merge = (state, action) => {
  const next = StateUtility.mergeData(state.data, action.data);
  return StateUtility.defaultSet(state, next);
};

/**
 * Adds a new category to the list.
 */
const create = (state, action) => {
  const next = [...state.data, action.data];
  return StateUtility.defaultSet(state, next);
};

/**
 * Updates an existing category in the list.
 */
const update = (state, action) => {
  const updated = action.data;
  const next = state.data.map(e => e.id === updated.id ? updated : e);
  return StateUtility.defaultSet(state, next);
};

/**
 * Removes a deleted category from the list.
 */
const _delete = (state, action) => {
  const next = state.data.filter(e => e.id !== action.data.id);
  return StateUtility.defaultSet(state, next);
};

/**
 * Removes a deleted subcategory from some category in the list.
 */
const deleteSubcategory = (state, action) => {
  const {categoryId, subcategoryId} = action.data;
  const next = state.data.map(e => {
    if (e.id === categoryId) {
      const {subcategories} = e;
      const nextSubcategories = subcategories?.filter(sc =>
          sc.id !== subcategoryId);
      return {...e, subcategories: nextSubcategories};
    }
    return e;
  });
  return StateUtility.defaultSet(state, next);
};
